.StudyMenuRoot {
    background-color: #0096A2;
    display: flex;
    flex-direction: column;
}

/* Should take up ~50% of screen */
.QuestionPromptSection {
    background-color: #0096A2;
    width: 94%;
    margin: 3%;
    padding: 2%;
    height: 44%;
    color: white;
    font-size: 30pt;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

/* Should take up ~40% of screen */
.QuestionResponseSection {
    width: 94%;
    margin: 3%;
    margin-top: 0;
    height: 34%;
    display: flex;
    flex-direction: column;
}

.QuestionResponseSectionRow {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 50%;
}

.QuestionResponseSectionCell {
    margin: 1%;
    width: 48%;
    border-radius: 10px;
    background-color: #E4BD0B !important;
    display: flex;
    flex-direction: row;
}

.QuestionResponseSectionCellNumber {
    height: 100%;
    width: 50px;
    background-color: #FFDD43;
    border-radius: 25px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 24pt;
    color: black;
}

.QuestionResponseSectionCellAnswer {
    height: 100%;
    flex: 1;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 16pt;
    color: black !important;
}

/* Should take up ~10% of screen */
.StudyControlSection {
    width: 94%;
    margin: 3%;
    margin-top: 0;
    height: 10%;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.ExitButton {
    background-color: #DC3545 !important;
    color: black !important;
    font-size: 24pt;
    border-radius: 10px;
    width: 150px;
}