.DecksHeader {
    background-color: #0096A2 !important;
    margin: 0px;
    text-align: center;
    color: #ffffff;
    padding: 3%;
    height: 150px;
}

.Logo {
    float: left;
    width: 60px;
    height: 60px;
    object-fit: contain;
}

.Options {
    float: right;
    height: 60px;
    width: 60px;
    object-fit: contain;
    cursor: pointer;
}

.StudySetHeaderTable {
    margin: 20px;
    border: 20px;
}

.StudySetHeaderButton {
    background-color: #fbae17 !important;
    border-color: #fbae17 !important;
    vertical-align: middle;
    margin-left: 1.5%;
    cursor: pointer;
    color: #000;
}

.SettingsButton {
    background-color: #fbae17 !important;
    border-color: #000000;
    color: #000;
}

.MainMenuHeader {
    background-color: #0096A2 !important;
    width: 100%;
    text-align: center;
    color: #ffffff;
}

.EnrolledClass {
    background-color: #0096A2 !important;
    border-color: #000000;
    color: #ffffff;
    margin-left: 1.5%;
    padding: 0;
    width: 97%;
    text-align: left;
    cursor: pointer;
}

.StudySets {
    margin-left: 1.5%;
    width: 97%;
}

.ClassInvitationRow {
    justify-content: space-between;
}

.MainMenuComponent {
    display: table-cell;
    height: 100%;
}

.home-nav-link .active {
    background-color: #fbae17 !important;
}

.SetSectionHeader {
    background-color: #fbae17 !important;
    margin: 20px;
    text-align: center;
    color: #000000;
    padding: 3%;
}

.Deck {
    background-color: #0096A2 !important;
    border-color: #000000;
    color: #ffffff;
    margin-left: 1.5%;
    margin-bottom: 20px;
    width: 97%;
    text-align: left;
    cursor: pointer;
}

.EnrolledClass {
    padding: 0 20px;
    margin: 20px;
    background-color: #0096A2 !important;
    color: #ffffff;
    padding: 3%;
    cursor: pointer;
}

.TaughtClass {
    padding: 0 20px;
    margin: 20px;
    background-color: #0096A2 !important;
    color: #ffffff;
    padding: 3%;
}

.HomeBackground {
    height: 100%;
}
