.ClassMenuHeader {
    display: flex;
    flex-direction: row;
    background-color: #0096A2 !important;
    margin-bottom: 20px;
}

.ClassMenuSelector {
    flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.ClassMenuHeaderInfo {
    color: white;
}

.ClassMenuBuffer {
    width: 104px;
}

.StudySetRows {
    margin-left: 10px;
    margin-right: 10px;
    display: flex;
    flex-direction: column;
}

.StudySetHeaderButton {
    margin-bottom: 15px;
    border: 1px solid black;
}

.StudySetRow {
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid #dddddd;
}

.SharedDeck {
    margin-left: 1.5%;
    width: 97%;
    text-align: left;
    margin-bottom: 10px;
    border: 1px solid black;
    background-color: #0096A2 !important;
}

.StudySetRowTitle {
    flex: 1;
}

.RosterListSection {
    width: 97%;
    margin-left: 1.5%;
    margin-right: 1.5%;
}

.RosterListSectionHeader {
    font-size: 16pt;
    font-weight: bold;
    border-bottom: 1px black solid;
    margin-bottom: 15px;
}

.StudentRow {
    margin-bottom: 10px;
    border-bottom: 1px black solid;
    display: flex;
    flex-direction: row;
}

.StudentRowName {
    flex: 1;
}

.LastStudentRow {
    margin-bottom: 10px;
    display: flex;
    flex-direction: row;
}

.StudentRowButton {
    background-color: #fdae17 !important;
    border-color: #fdae17 !important;
    color: black;
    border: 1px black solid !important;
}

.GoogleClassroomLinkButton {
    background-color: #fdae17 !important;
    border-color: black;
    color: black;
    max-width: 150px;
}

.GoogleClassRow {
    width: 95%;
    margin-left: 2.5%;
    margin-top: 1%;
    margin-bottom: 1%;
    border-bottom: black 1px solid;
    display: flex;
    flex-direction: row;
}

.GoogleClassRowTitle {
    flex: 1;
}

.GoogleClassRowButton {
    background-color: #fdae17 !important;
    border-color: black;
    color: black;
}

.AssignmentRow {
    width: 95%;
    margin-left: 2.5%;
    margin-top: 1%;
    margin-bottom: 1%;
    border-bottom: black 1px solid;
    display: flex;
    flex-direction: row;
}

.AssignmentRowTitle {
    flex: 1;
}

.AssignmentRowButton {
    background-color: #fdae17 !important;
    border-color: black;
    color: black;
}

.DisplayAssignmentTopRow {
    width: 100%;
    display: flex;
    flex-direction: row;
}

.DisplayAssignmentTitle {
    flex: 1;
}

.DisplayAssignmentBottomRow {
    display: flex;
    width: 100%;
    flex-direction: row;
}

.DisplayAssignmentInstructions {
    flex: 1;
}