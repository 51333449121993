.Actions {
    display: flex;
    flex-direction: column;
}

.Header {
    background-color: #0096A2;
    width: 100%;
    color: white;
    margin-bottom: 10px;
}

.ActionsElement {
    margin: 5px;
    background-color: #fbae17 !important;
    border-color: #000000;
    color: #000000;
}

.DeleteButton {
    background-color: #dc3545 !important;
    border-color: black;
    color: black;
}

.InputBar {
    color: white;
}

.Questions {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.Question {
    background: #dddddd;
    margin-left: 1.5%;
    display: flex;
    flex-direction: column;
    width: 97%;
    margin-bottom: 10px;
    border: 1px solid black;
}

.ShareableLinkBar {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: stretch;
}

.ShareSpace {
    flex: 1;
}

.QuestionHeader {
    width: 100%;
    background-color: #fbae17;
    padding-left: 5px;
    border-bottom: 1px solid black;
}

.AnswersTable {
    display: flex;
    flex-direction: column;
}

.AnswersRow {
    display: flex;
    flex-direction: row;
}

.AnswersCell {
    width: 46%;
    margin-top: 5px;
    margin-bottom: 5px;
    margin-left: 2.5%;
    background-color: #cccccc;
    padding: 3px;
    border: 1px solid black;
}

.QuestionHeaderRow1 {
    display: flex;
    flex-direction: row;
}

.QuestionHeaderRow1Label {
    flex: 1;
}

.CorrectAnswerCell {
    width: 46%;
    margin-top: 5px;
    margin-bottom: 5px;
    margin-left: 2.5%;
    background-color: #b3fb17 !important;
    padding: 3px;
    border: 1px solid black;
}

.ShareableLinkBlock {
    margin: 5%;
    width: 90%;
    margin-bottom: 0px;
}

.TeacherShareHeader {
    margin: 30px;
    margin-bottom: 0px;
    margin-top: 0px;
}

.SetMenuActionBar {
    width: 97%;
    margin-left: 1.5%;
    margin-bottom: 10px;
}

.SetMenuActionBarButton {
    margin: 0;
    background-color: #fbae17 !important;
    border: 1px black solid;
    color: black;
}

.BlackTextInput {
    border-bottom: 1px black solid !important;
}
